<template>
  <v-card
    outlined
    class="style_card d-flex flex-column flex-grow-1"
    style="max-height: calc(100vh - 64px); position: relative"
  >
    <div class="sticky">
      <div
        class="d-flex px-1 pt-2 pb-1"
        :class="[smallHeader ? 'small-header' : '']"
      >
        <slot name="header_right">
          <v-list-item class="header__right__list_item">
            <v-list-item-content>
              <v-list-item-title class="headline mb-1 d-flex">
                <v-tooltip bottom v-if="useBack">
                  <template v-slot:activator="{ on }">
                    <v-btn class="mr-1" icon v-on="on" @click="goBack">
                      <v-icon size="30" color="#000000c7"
                        >mdi-arrow-left</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Quay lại</span>
                </v-tooltip>
                <v-icon
                  v-if="icon && !useBack"
                  class="mr-1"
                  size="30"
                  color="#000000c7"
                  >{{ icon }}</v-icon
                >
                <div class="d-flex align-center">
                  <div class="text-uppercase title__style">{{ title }}</div>

                  <slot name="add" v-if="useAdd">
                    <div style="height: 37px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            class="mx-2"
                            width="25px"
                            height="25px"
                            fab
                            small
                            depressed
                            size
                            color="var(--primary-color)"
                            @click="$emit('add')"
                          >
                            <v-icon size="18px" color="#fff"> mdi-plus </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ titleAdd }}</span>
                      </v-tooltip>
                    </div>
                  </slot>
                  <slot name="extra_add"></slot>
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>{{ subTitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </slot>
        <v-card-title class="py-0 pr-0 ml-auto">
          <div class="d-flex">
            <div>
              <slot name="side-right"></slot>
            </div>
            <span v-if="!noFilter">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    @click="clickFilter"
                    :class="[isShowFilter ? null : 'mr-2']"
                  >
                    <v-icon color="#000"
                      >{{ iconFilter.icon }} mdi-filter</v-icon
                    >
                  </v-btn>
                </template>
                <span>{{ iconFilter.tooltip }}</span>
              </v-tooltip>
            </span>
          </div>
        </v-card-title>
      </div>
      <div
        class="mt-2 separation"
        :class="[isShowFilter ? null : 'px-5']"
      ></div>
    </div>
    <div class="d-flex flex-grow-1 style_main_content_wrap">
      <div class="flex-grow-1 px-5 mt-5 style_main_content overflow">
        <div style="min-height: min-content">
          <slot></slot>
        </div>
      </div>
      <div v-show="isShowFilter" class="flex-grow-0 siderbar_filter pt-2">
        <v-card
          :width="widthFilter"
          height="100%"
          class="d-flex flex-column"
          flat
        >
          <v-toolbar class="flex-grow-0" dense flat color="#fff" height="30px">
            <v-toolbar-title class="text-capitalize title--filter">
              <slot name="title-filter">
                <div>
                  <v-icon color="#000" class="mr-0">mdi-filter</v-icon> Bộ lọc
                </div>
              </slot>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!noReset"
              icon
              @click="goResetFilter"
              title="Làm mới"
              height="30px"
              width="30px"
            >
              <v-icon color="#000">mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              @click="isShowFilter = false"
              icon
              height="30px"
              width="30px"
              title="thoát"
            >
              <v-icon color="#000">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="separation px-2 mt-2 mb-3"></div>

          <div
            class="overflow-auto fill-height px-2 d-flex flex-column"
            style="background-color: #fff"
          >
            <slot name="filter"></slot>

          </div>
        </v-card>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    icon: String,
    useBack: Boolean,
    title: String,
    subTitle: String,
    noFilter: Boolean,
    widthFilter: { type: String, default: "306px" },
    useAdd: Boolean,
    smallHeader: Boolean,
    titleAdd: { type: String, default: "Thêm mới" },
    linkBack: String,
    iconFilter: {
      type: Object,
      default: () => ({ icon: "mdi-filter", tooltip: "Bộ lọc" }),
    },
  },
  computed: {
    slotTable() {
      return Object.keys(this.$scopedSlots)
        .filter((x) => x.includes("table."))
        .map((x) => x.replace("table.", ""));
    },
  },
  watch: {
    isShowFilter(val) {
      this.$emit("isShowFilter", val);
    },
  },
  data: () => ({
    isShowFilter: false,
    noReset: false,
  }),
  methods: {
    clickFilter() {
      this.isShowFilter = !this.isShowFilter;
    },
    goBack() {
      if (this.linkBack) {
        this.$router.push(this.linkBack);
      }
    },
    goResetFilter() {
      this.$emit("reset-filter");
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin before-separation {
  background-color: #cecece;
  content: "";
  width: 100%;
  display: block;
}
.siderbar_filter {
  border-left: 2px solid #cecece;
  .v-toolbar__content {
    padding-left: 4px;
  }
}
.title--filter {
  font-size: 1rem;
  font-weight: 600;
}
.style_card {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.separation {
  width: 100%;
  position: relative;
  height: 1px;
  &-1 {
    &::before {
      @include before-separation;
      height: 1px;
    }
  }
  &::before {
    height: 2px;
    @include before-separation;
  }
}
.siderbar {
  > .v-card {
    background: #eceff1;
  }
  .siderbar--reset__filter {
    header {
      width: 20px;
    }
  }
}
</style>
<style scoped>
/deep/.v-list-item.header__right__list_item {
  height: 60px;
}
.siderbar_filter /deep/.v-toolbar__content {
  padding-left: 4px;
}
.siderbar_filter /deep/ .v-input .v-label {
  height: 30px;
  font-size: 14px;
}
/deep/.v-list-item.header__right__list_item {
  height: 40px;
}
/deep/ .header__right__list_item .v-list-item__content {
  padding-bottom: 0;
}
</style>
<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 0;
}
.overflow {
  overflow: auto;
}
</style>
<style>
.title__style {
  font-weight: 500;
}
.v-footer {
  display: none;
}
.v-main__wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 64px 0px 0px;
}
html {
  overflow: hidden;
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 7px;
  padding-left: 0;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 4px;
  margin-bottom: 0px;
}
.style_main_content {
  width: 100%;
}
.style_main_content_wrap {
  height: calc(100% - 15%);
}
.small-header {
  height: 60px !important;
}
</style>
