<template>
  <div
    class="box import text-uppercase d-flex justify-center align-center py-2"
    :class="[drag ? 'drag' : '', url_image ? 'hasImage' : '']"
    @click="goImport"
    @dragover="
      overrideDefault($event);
      hoverDrag();
    "
    @dragenter="
      overrideDefault($event);
      hoverDrag();
    "
    @dragleave="
      overrideDefault($event);
      hoverDragEnd();
    "
    @drop="
      overrideDefault($event);
      hoverDragEnd();
      changeFile($event);
    "
    :style="`width:${width};height:${height}`"
  >
    <div v-if="url_image">
      <v-img :height="height_img" :width="width_img" :src="url_image" />
    </div>
    <div class="d-flex flex-column justify-center align-center" v-else>
      <v-icon class="mr-2" size="30">mdi-file-image-outline</v-icon>
      <div>
        {{ title || "Tải hoặc kéo thả hình ảnh" }}
      </div>
    </div>
    <input
      @change="changeFile"
      ref="image_file"
      type="file"
      hidden
      :multiple="false"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
    title: String,
    width: { type: String, default: "" },
    height: { type: String, default: "" },
    width_img: { type: String, default: "200px" },
    height_img: { type: String, default: "200px" },
    readonly: Boolean,
  },
  data: (vm) => ({
    drag: false,
    url_image:
      (vm?.value?.image?.link && vm.getImageUrl(vm.value.image.link)) || null,
  }),
  computed: {
    imgFile: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    imgFile: {
      handler(val) {
        if (!val) {
          this.url_image = this.$options.data.call(this).url_image;
        }
      },
      deep: true,
    },
  },
  methods: {
    hoverDrag() {
      this.drag = true;
    },
    hoverDragEnd() {
      this.drag = false;
    },
    overrideDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    goImport() {
      if (this.readonly) {
        return;
      }
      this.$refs.image_file.click();
    },
    getImageUrl(url) {
      if (url.charAt(0) === "/") {
        url = url.substring(1);
      }
      let path = `${process.env.VUE_APP_BASE}${url}`;
      return path;
    },
    warning(text) {
      this.$toast.warning(text, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    changeFile(e) {
      if (this.readonly) {
        this.$refs.image_file.value = null;
        return;
      }
      let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
      this.$refs.image_file.value = null;
      var filePath = file.name.split(".").pop(); //lấy định dạng file
      var dinhDangChoPhep = ["jpg", "jpeg", "png"]; //các tập tin cho phép
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.warning("Kích thước file ảnh tối đa 20Mb!");
        return false;
      }
      if (!dinhDangChoPhep.find((el) => el == filePath.toLowerCase())) {
        this.warning("Tập tin không hợp lệ!");
        return;
      }
      this.imgFile.file = file;
      this.$set(this.imgFile, "url_image", URL.createObjectURL(file));
      this.url_image = URL.createObjectURL(file);
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin highLight {
  border-color: var(--primary-color);
  color: var(--primary-color) !important;
  i {
    color: var(--primary-color) !important;
  }
}
.box {
  cursor: pointer;
  border-width: 2px;
  border-color: #aaa6a6;
  &.item {
    border-style: solid;
  }
  &.drag {
    @include highLight;
  }
  &.import {
    border-style: dashed;
    &:hover {
      @include highLight;
    }
  }
  &.hasImage {
    border: none !important;
  }
}
</style>
