import Layout from "@/components/Layout/Layout";

// Pages
import Dashboard from "@/pages/Dashboard/Dashboard";
// import Typography from "@/pages/ElementUI/Typography/Typography";
// import Tables from "@/pages/ElementUI/Tables/Basic";
// import Notifications from "@/pages/ElementUI/Notifications/Notifications";
// import Icons from "@/pages/ElementUI/Icons/Icons";
// import Charts from "@/pages/ElementUI/Charts/Charts";
// import Maps from "@/pages/ElementUI/Maps/Google";
import Error from "@/pages/Error/Error";
import Login from "@/pages/Login/Login";
import Blank from "@/components/Blank/Layout";

export const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "*",
    name: "Error",
    component: Error,
  },
  {
    path: "/",
    redirect: "/dashboard",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "dashboard",
        name: "Tổng quan",
        component: Dashboard,
      },
      {
        path: "users",
        name: "Người dùng",
        component: Blank,
        children: [
          {
            path: "menus",
            component: () => import("@/pages/User/menus/index"),
            name: "Menu",
          },
          {
            path: "role",
            component: () => import("@/pages/User/roles/index"),
            name: "Phân quyền",
          },
          {
            path: "profile",
            component: () => import("@/pages/User/profile/index"),
            name: "Thông tin",
          },
        ],
      },
      {
        path: "danhmuc",
        name: "Danh mục",
        component: () => import("@/pages/Danhmuc/index"),
      },

      {
        path: "quanlykhachhang",
        name: "Quản lý khách hàng",
        component: Blank,
        children: [
          {
            path: "nhomkhachhang",
            component: () =>
              import("@/pages/Quanlykhachhang/Nhomkhachhang/index"),
            name: "Nhóm khách hàng",
          },
          {
            path: "danhsachkhachhang",
            component: () =>
              import("@/pages/Quanlykhachhang/Danhsachkhachhang/index"),
            name: "Danh sách khách hàng",
          },
          {
            path: "themmoikhachhang",
            component: () =>
              import("@/pages/Quanlykhachhang/Danhsachkhachhang/detail"),
            name: "Chi tiết khách hàng",
            props: true,
          },
          {
            path: "chitietkhachhang/:id",
            component: () =>
              import("@/pages/Quanlykhachhang/Danhsachkhachhang/detail"),
            name: "Chi tiết khách hàng",
            props: true,
          },
        ],
      },
      {
        path: "quanly",
        name: "Quản lý thông tin chung",
        component: Blank,
        children: [
          {
            path: "khuvuc",
            component: () => import("@/pages/Quanlythongtin/Khuvuc/index"),
            name: "Khu vực",
          },
          {
            path: "loaisanpham",
            component: () => import("@/pages/Quanlythongtin/Loaisanpham/index"),
            name: "Loại sản phẩm",
          },
          {
            path: "sanpham",
            component: () => import("@/pages/Quanlythongtin/Sanpham/index"),
            name: "Sản phẩm",
          },
          {
            path: "loaihopdong",
            component: () => import("@/pages/Quanlythongtin/Loaihopdong/index"),
            name: "Loại hợp đồng",
          },
          {
            path: "congty",
            component: () => import("@/pages/Quanlythongtin/Congty/index"),
            name: "Danh sách công ty",
          },
          {
            path: "hoadon",
            component: () => import("@/pages/Quanlythongtin/Hoadon/index"),
            name: "Hóa đơn",
          },
          {
            path: "tinh-huyen",
            component: () => import("@/pages/Quanlythongtin/Tinhhuyen/index"),
            name: "Tỉnh huyện",
          },
        ],
      },
      {
        path: "kinhdoanh",
        name: "Quản lý kinh doanh",
        component: Blank,
        children: [
          {
            path: "yeucaubaogia",
            component: () =>
              import("@/pages/Quanlykinhdoanh/Yeucaubaogia/index"),
            name: "Yêu cầu báo giá",
          },
          {
            path: "themyeucaubaogia",
            component: () =>
              import("@/pages/Quanlykinhdoanh/Yeucaubaogia/create"),
            name: "Chỉnh sửa yêu cầu báo giá",
          },
          {
            path: "capnhatyeucaubaogia/:id",
            component: () =>
              import("@/pages/Quanlykinhdoanh/Yeucaubaogia/create"),
            name: "Chỉnh sửa yêu cầu báo giá",
          },
          {
            path: "quanlybaogia",
            component: () => import("@/pages/Quanlykinhdoanh/Baogia/index"),
            name: "Báo giá",
          },
          {
            path: "thembaogia",
            component: () => import("@/pages/Quanlykinhdoanh/Baogia/create"),
            name: "Chỉnh sửa báo giá",
          },
          {
            path: "thembaogia/:id",
            component: () => import("@/pages/Quanlykinhdoanh/Baogia/create"),
            name: "Chỉnh sửa báo giá",
          },
          {
            path: "thembaogia/themhopdong/:baogia_id",
            component: () =>
              import("@/pages/Quanlykinhdoanh/Baogia/AddContract"),
            name: "Chỉnh sửa hợp đồng",
          },
          {
            path: "quanlyhopdong",
            component: () => import("@/pages/Quanlykinhdoanh/Hopdong/index"),
            name: "Hợp đồng",
          },
          {
            path: "themhopdong",
            component: () => import("@/pages/Quanlykinhdoanh/Hopdong/create"),
            name: "Chỉnh sửa hợp đồng",
          },
          {
            path: "themhopdong/:id",
            component: () => import("@/pages/Quanlykinhdoanh/Hopdong/create"),
            name: "Chỉnh sửa hợp đồng",
          },
          {
            path: "quanlychungtu",
            component: () => import("@/pages/Quanlykinhdoanh/Chungtu/index"),
            name: "Chứng từ",
          },
          {
            path: "bienbannghiemthu",
            name: "Biên bản nghiệm thu",
            component: () =>
              import("@/pages/Quanlykinhdoanh/BienBanNghiemThu/index"),
          },
          {
            path: "themmoibienbangiaonhan",
            component: () =>
              import("@/pages/Quanlykinhdoanh/BienBanGiaoNhan/create-edit"),
            name: "Chi tiết biên bản giao nhận",
          },
          {
            path: "chitietbienbangiaonhan/:id",
            component: () =>
              import("@/pages/Quanlykinhdoanh/BienBanGiaoNhan/create-edit"),
            name: "Chi tiết biên bản giao nhận",
          },
          {
            path: "chitietbienbannghiemthu/:id",
            component: () =>
              import("@/pages/Quanlykinhdoanh/BienBanGiaoNhan/create-edit"),
            name: "Chi tiết biên bản nghiệm thu",
          },
          {
            path: "bienbangiaonhan",
            name: "Biên bản giao nhận",
            component: () =>
              import("@/pages/Quanlykinhdoanh/BienBanGiaoNhan/index"),
          },
          {
            path: "quanlychiphi",
            component: () =>
              import("@/pages/Quanlykinhdoanh/Quanlychiphi/index"),
            name: "Quản lý chi phí",
          },
        ],
      },
      {
        path: "tokhai",
        name: "Quản lý tờ khai",
        component: Blank,
        children: [
          {
            path: "tokhaihaiquan",
            component: () => import("@/pages/Quanlytokhai/Tokhaihaiquan/index"),
            name: "Tờ khai hải quan",
          },
          {
            path: "khuvuchaiquan",
            component: () => import("@/pages/Quanlytokhai/KhuVucHaiQuan/index"),
            name: "Khu vực hải quan",
          },
          {
            path: "congtynhapkhau",
            component: () =>
              import("@/pages/Quanlytokhai/Congtynhapkhau/index"),
            name: "Công ty nhập khẩu",
          },
          {
            path: "congtyxuatkhau",
            component: () =>
              import("@/pages/Quanlytokhai/Congtyxuatkhau/index"),
            name: "Công ty xuất khẩu",
          },
          {
            path: "chinhanhnganhang",
            component: () =>
              import("@/pages/Quanlytokhai/Chinhanhnganhang/index"),
            name: "Chi nhánh ngân hàng",
          },
          {
            path: "chinhsuatokhaihaiquan",
            component: () =>
              import("@/pages/Quanlytokhai/Tokhaihaiquan/create"),
            name: "Chỉnh sửa tờ khai hải quan",
          },
          {
            path: "chinhsuatokhaihaiquan/:id",
            component: () =>
              import("@/pages/Quanlytokhai/Tokhaihaiquan/create"),
            name: "Chỉnh sửa tờ khai hải quan",
          },
        ],
      },
      {
        path: "kpi",
        name: "Quản lý KPI",
        component: Blank,
        children: [
          {
            path: "quanlykpi",
            name: "Danh sách KPI",
            component: () => import("@/pages/Kpi/QuanlyKpi/index"),
            children: [],
          },
          {
            path: "quanlykpi/show/:id",
            name: "Chi tiết KPI",
            component: () => import("@/pages/Kpi/QuanlyKpi/show"),
          },
          {
            path: "kpitangtruongthiphan",
            name: "Kpi tăng trưởng thị phần",
            component: () => import("@/pages/Kpi/Kiptangtruongthiphan/index"),
          },
        ],
      },
      {
        path: "quanlyyeucaukhieunai",
        name: "Quản lý yêu cầu - khiếu nại",
        component: Blank,
        children: [
          {
            path: "yeucau",
            component: () => import("@/pages/KhieuNaiYeucau/YeuCau/index"),
            name: "Yêu cầu",
          },
          {
            path: "chitietyeucau/:id",
            component: () =>
              import("@/pages/KhieuNaiYeucau/YeuCau/create-edit"),
            name: "Chi tiết yêu cầu",
          },
          {
            path: "khieunai",
            component: () => import("@/pages/KhieuNaiYeucau/KhieuNai/index"),
            name: "Khiếu nại",
          },
          {
            path: "chitietkhieunai/:id",
            component: () =>
              import("@/pages/KhieuNaiYeucau/KhieuNai/create-edit"),
            name: "Chi tiết khiếu nại",
          },
        ],
      },

      // khaimp them router bao cao 04/11/2021
      {
        path: "baocao",
        name: "Báo cáo",
        component: () => import("@/pages/Baocao/index"),
      },
      {
        path: "baocao/thiphan",
        name: "Chi tiết báo cáo",
        component: () => import("@/pages/Baocao/BaoCaoTangTruongThiPhan/index"),
      },
      {
        path: "baocao/kinhdoanh",
        name: "Chi tiết báo cáo",
        component: () => import("@/pages/Baocao/BaoCaoKetQuaKinhDoanh/index"),
      },
      {
        path: "baocao/khachhangtrongtam",
        name: "Chi tiết báo cáo",
        component: () => import("@/pages/Baocao/BaoCaoKhachHangTrongTam/index"),
      },
      {
        path: "baocao/khieunai",
        name: "Chi tiết báo cáo",
        component: () => import("@/pages/Baocao/BaoCaoKhieuNai/index"),
      },
      {
        path: "baocao/huyhang",
        name: "Chi tiết báo cáo",
        component: () => import("@/pages/Baocao/BaoCaoChungTuHuyHang/index"),
      },
      {
        path: "setting",
        name: "Cài đặt chung",
        component: () => import("@/pages/CauHinhNgayHetHan/index"),
      },
    ],
  },
];
