<template>
  <div>
    <v-text-field
      outlined
      dense
      label="Chọn vị trí"
      readonly
      hide-details
      v-bind="$attrs"
      :value="input_text"
      append-icon="mdi-map"
      @click:append="onClickIcon"
    >
    </v-text-field>
    <v-dialog v-model="show" persistent hide-overlay width="35%">
      <v-card min-width="35%" min-height="130px">
        <v-app-bar dense outlined color="#006EC5">
          <div class="d-flex align-center">
            <v-icon left color="white">mdi-map</v-icon>
            <span class="white--text">Bản đồ</span>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="close" small class="mr-1">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <div class="pa-2">
          <GmapMap
            :center="{ lat: 21.182006, lng: 106.061761 }"
            :zoom="10"
            map-type-id="terrain"
            style="width: 100%; height: 400px ;border: 1px solid grey;"
            :options="options"
            @click="onClickMap"
          >
            <gmap-marker
              :position="point"
              :clickable="true"
              :draggable="true"
              @drag="updateCoordinates"
            ></gmap-marker>
          </GmapMap>
        </div>
        <div class="pb-3 mr-3 d-flex justify-end">
          <v-btn color="#006EC5" hide-details @click="addCoordinate">
            <span class="white--text">
              Xong
            </span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    show: false,
    point: { lat: 21.178196752591845, lng: 106.07098589905831 },
  }),
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    options() {
      return {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: false,
      };
    },
    input_text() {
      if (this.form && Object.keys(this.form).length) {
        return `lat:${this.form.lat}, lng:${this.form.lng}}`;
      }
      return null;
    },
  },
  methods: {
    onClickIcon() {
      if (this.form && Object.keys(this.form)) {
        this.point = Object.assign(this.point, this.form);
        this.point.lng = parseFloat(this.point.lng);
        this.point.lat = parseFloat(this.point.lat);
      }
      this.show = true;
    },
    close() {
      this.show = false;
    },
    addCoordinate() {
      this.form = this.point;
      this.show = false;
      this.point = this.$options.data.call(this).point;
    },
    onClickMap(event) {
      this.point.lat = event.latLng.lat();
      this.point.lng = event.latLng.lng();

      this.form = this.point;
    },
    updateCoordinates(event) {
      this.onClickMap(event);
    },
  },
};
</script>

<style scoped></style>
