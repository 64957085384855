<template>
  <div>
    <div
      class="box import text-uppercase d-flex justify-center align-center py-2"
      :class="[drag ? 'drag' : '']"
      @click="goImport"
      @dragover="
        overrideDefault($event);
        hoverDrag();
      "
      @dragenter="
        overrideDefault($event);
        hoverDrag();
      "
      @dragleave="
        overrideDefault($event);
        hoverDragEnd();
      "
      @drop="
        overrideDefault($event);
        hoverDragEnd();
        changeFile($event);
      "
    >
      <v-icon class="mr-2" size="30">mdi-file-document</v-icon>
      {{ title || "Tải hoặc kéo thả tệp tin" }}
      <input
        @change="changeFile"
        ref="box_input_file"
        type="file"
        hidden
        :multiple="multiple"
        :accept="accept"
      />
    </div>

    <div class="mt-2 flex-grow-1" v-if="files && files.length > 0">
      <CustomTable
        :headers="headers"
        :items="files"
        hide-default-footer
        hide-default-header
      >
        <template v-slot:[`item.icon`]="{ item }">
          <div :title="item.stt">
            <v-icon size="40" :color="colorType(item.typeFile)">{{
              typeIconHandle(item.typeFile)
            }}</v-icon>
          </div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div class="my-2">
            <div class="d-flex justify-space-between align-center">
              <div
                class="d-flex align-center"
                style="min-width: 170px"
                v-if="edit != item.stt"
              >
                <span>{{ item.name }}</span>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="text-capitalize ml-2"
                      v-on="on"
                      height="22px"
                      width="22px"
                      @click="setEdit(item)"
                    >
                      <v-icon size="18">mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Chỉnh sửa</span>
                </v-tooltip>
              </div>
              <div v-else class="d-flex align-center">
                <v-tooltip v-if="edit != item.stt" bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      {{ item.name }}
                    </div>
                  </template>
                  <span>Chỉnh sửa tên</span>
                </v-tooltip>
                <div v-else>
                  <input
                    ref="suggestForm"
                    autofocus
                    :style="
                      `outline: transparent; max-width: 300px; width: ${edit_value.length *
                        8}px;`
                    "
                    v-model="edit_value"
                    @keyup.enter="save(item)"
                    @keyup.esc="cancelEdit()"
                  />
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="text-capitalize"
                      v-on="on"
                      height="22px"
                      width="22px"
                      @click="save(item)"
                    >
                      <v-icon size="18">mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Lưu</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      class="text-capitalize"
                      v-on="on"
                      height="22px"
                      width="22px"
                      @click="cancelEdit()"
                    >
                      <v-icon size="18">mdi-cancel</v-icon>
                    </v-btn>
                  </template>
                  <span>Huỷ bỏ</span>
                </v-tooltip>
              </div>
            </div>
            <div
              class="mt-2"
              v-html="
                `<strong>Size:</strong> ${formatSize(
                  item.size
                )} | <strong>Type:</strong> ${item.typeFile}`
              "
            ></div>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-show="hasImport"
            size="12"
            icon
            @click="confirmImport(item)"
            :loading="isLoadingHorizontal"
            :class="{ cusor: isLoadingHorizontal }"
            ><v-icon>mdi-swap-horizontal</v-icon></v-btn
          >
          <v-btn size="12" icon @click="downloadItem(item)"
            ><v-icon>mdi-download-outline</v-icon></v-btn
          >
          <v-btn size="12" icon @click="removeFile(item)"
            ><v-icon>mdi-delete-outline</v-icon></v-btn
          >
        </template>
      </CustomTable>
    </div>
  </div>
</template>
<script>
import { byteFormat } from "@/utils/size";
import { download, destroy, multipleStore, update } from "@/api/document";
import { saveAs } from "file-saver";
import JsonToFromData from "@/utils/JsonToFormData.js";
export default {
  props: {
    value: { type: Array, default: () => [] },
    multiple: Boolean,
    hasImport: Boolean,
    title: String,
    typeAllow: Array,
    sizeAllow: Number, //MB,
    editing: Boolean,
    type: { type: String, default: "" },
    table: { type: String, default: "" },
    reference_id: [String, Number],
    noComfirmImport: Boolean,
    disabled: Boolean,
    accept: String,
    isLoadingHorizontal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      { text: "icon", align: "start", value: "icon", class: "pl-1 pr-0" },
      { text: "Tên", align: "start", value: "name" },
      { text: "Hành động", align: "end", value: "actions", width: "200px" },
    ],
    edit: null,
    edit_value: null,
    drag: false,
  }),
  computed: {
    files: {
      get() {
        if (!this.value) {
          return [];
        }
        return this.value.map((x, index) => ({
          stt: index + 1,
          typeFile: x.name.split(".").pop(),
          ...x,
        }));
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    files(val) {
      if (!val.length) {
        this.$refs.box_input_file.value = "";
      }
    },
  },
  methods: {
    colorType(type) {
      if (["xls", "xlsx"].includes(type)) {
        return "var(--primary-color)";
      } else if (["doc", "docx"].includes(type)) {
        return "#2b5592";
      } else if (["pdf"].includes(type)) {
        return "#da2b2a";
      }
      return "#6f6f6f";
    },
    typeIconHandle(type) {
      if (["xls", "xlsx"].includes(type)) {
        return "mdi-file-excel-outline";
      } else if (["doc", "docx"].includes(type)) {
        return "mdi-file-word-outline";
      } else if (["pdf"].includes(type)) {
        return "mdi-file-pdf-box";
      }
      return "mdi-file-outline";
    },
    confirmImport(item) {
      if (this.noComfirmImport) {
        this.importItem(item);
      } else {
        this.$confirmBox.show({
          title: "Import chứng từ",
          width: 500,
          body:
            "Bạn có chắc chắn muốn import dữ liệu từ file chứng từ này vào tờ khai không?",
          action: async () => await this.importItem(item),
          onDone: () => ({}),
        });
      }
    },
    async importItem(item) {
      if (item.file) {
        if (item.typeFile == "pdf") {
          this.$emit("import-item-pdf", item);
        } else {
          this.$emit("import-item", item);
        }
      } else if (item.id) {
        const res = await download(item.id);
        let file = new File([res], item.name);
        if (item.typeFile == "pdf") {
          this.$emit("import-item-pdf", { file });
        } else {
          this.$emit("import-item", { file });
        }
      }
    },
    hoverDrag() {
      if (this.disabled) {
        return;
      }
      this.drag = true;
    },
    hoverDragEnd() {
      this.drag = false;
    },
    overrideDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    goImport() {
      if (this.disabled) {
        return;
      }
      this.$refs.box_input_file.click();
    },
    async changeFile(e) {
      if (this.disabled) {
        return;
      }
      let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      for (let i = 0; i < files.length; i++) {
        let element = files[0];
        if (this.typeAllow && this.typeAllow.length > 0) {
          if (!this.typeAllow.includes(element.name.split(".").pop())) {
            this.$toast.warning("Tập tin không hợp lệ!", {
              position: "top-center",
              timeout: 2000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            });
            return;
          }
        }
        if (this.sizeAllow) {
          if (element.size / 1024 / 1024 > this.sizeAllow) {
            this.$toast.warning(`Kích thước file tối đa ${this.sizeAllow}Mb!`, {
              position: "top-center",
              timeout: 2000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            });
            return false;
          }
        }
      }

      if (this.editing && this.reference_id) {
        let data = JsonToFromData(
          {
            type: this.type,
            table: this.table,
            reference_id: this.reference_id,
            files: Array.from(files),
          },
          null
        );
        try {
          const res = await multipleStore(data);
          files = res.data;
        } catch (error) {
          return;
        }
      } else {
        files = Array.from(files).map((e) => ({
          name: e.name,
          size: e.size,
          file: e,
        }));
      }
      if (!this.multiple) {
        this.files = files;
        // console.log(1);
        // this.files = [];
        // console.log({this: [...this.files], files: [...files]})
      } else {
        this.files = [...this.files, ...files];
      }
      this.$emit("change", files);
    },
    async downloadItem(item) {
      if (item.id) {
        const res = await download(item.id);
        saveAs(new Blob([res]), item.name);
      } else if (item.file) {
        saveAs(new Blob([item.file]), item.name);
      }
    },
    async removeFile(item) {
      let indexFiles = this.files.map((x) => x.stt);
      let newFile = [...this.files];
      let find = indexFiles.indexOf(item.stt);
      if (find != -1) {
        if (this.editing && item.id) {
          try {
            await this.deleteItem(item, () => {
              newFile.splice(find, 1);
            });
          } catch (error) {
            return;
          }
        } else {
          newFile.splice(find, 1);
        }
      }
      this.files = newFile;
    },
    async deleteItem(item, callback = () => ({})) {
      this.$confirmBox.show({
        title: "Xóa tệp tin",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa tệp tin?",
        action: async () => await destroy(item.id),
        onDone: () => callback(),
        disableMessage: true,
      });
    },
    async save(item) {
      let name = this.edit_value;
      if (this.edit_value.split(".").pop() != item.typeFile) {
        name = `${name}.${item.typeFile}`;
      }
      if (this.files[item.stt - 1].name != name) {
        this.files[item.stt - 1].file = new File(
          [this.files[item.stt - 1].file],
          name
        );
        this.files[item.stt - 1].name = name;
        if (this.editing && this.files[item.stt - 1].id) {
          try {
            await this.handleChangeFileName(this.files[item.stt - 1]);
          } catch (error) {
            return;
          }
        }
        this.$emit("change-file-name", this.files[item.stt - 1]);
      }

      this.cancelEdit();
    },
    setEdit(item) {
      this.edit = item.stt;
      this.edit_value = item.name;
    },
    cancelEdit() {
      this.edit = null;
      this.edit_value = null;
    },
    formatSize(value) {
      return byteFormat(value);
    },
    async handleChangeFileName(item) {
      await update(item.id, item);
    },
  },
};
</script>
<style scoped>
.cusor {
  cursor: pointer;
}
/deep/ .v-data-table__wrapper tbody tr td:first-child {
  padding-right: 0;
  padding-left: 5px;
  width: 40px;
}
</style>
<style lang="scss" scoped>
$height: 170px;
@mixin highLight {
  border-color: var(--primary-color);
  color: var(--primary-color) !important;
  i {
    color: var(--primary-color) !important;
  }
}
.box {
  cursor: pointer;
  border-width: 2px;
  border-color: #aaa6a6;
  &.item {
    border-style: solid;
  }
  &.drag {
    @include highLight;
  }
  &.import {
    border-style: dashed;
    &:hover {
      @include highLight;
    }
  }
  //   width: $height;
  //   height: $height;
}
.table-file {
  height: $height;
}
</style>
