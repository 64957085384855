<template>
  <div class="container">
    <div id="chart">
      <apexchart
        :key="`chart-key-${key}`"
        type="bar"
        height="250"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import { report } from "@/api/baogia.js";
export default {
  data() {
    return {
      key: 0,
      data: {},
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Số lượng",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " Báo giá ";
            },
          },
        },
        colors: ["#42ba96", "#F44336"],
      },
      name: [],
    };
  },
  mounted() {
    this.hanlde_getdata();
  },
  methods: {
    async hanlde_getdata() {
      const res = await report();
      this.data = res.data;
      this.handle_render_data(this.data);
    },
    handle_render_data() {
      // let name = [];
      let sucess = [];
      let exception = [];
      this.series = [];
      this.chartOptions.xaxis.categories = [];
      this.data.forEach((e) => {
        this.chartOptions.xaxis.categories.push("Tháng " + e.month);
        sucess.push(e.sucess);
        exception.push(e.exception);
      });
      this.series.push(
        {
          name: "Đã lập hợp đồng",
          data: sucess,
        },
        {
          name: "Không thành công",
          data: exception,
        }
      );
      this.key++;
    },
  },
  watch: {
    target: function () {
      return this.renderData();
    },
  },
};
</script>

<style>
.container {
  margin-top: 10px;
  width: 100%;
}
</style>
