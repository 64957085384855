import request from "../utils/request";

export function getProductPrices(params) {
  return request({
    url: "productprice",
    method: "get",
    params,
  });
}
export function getProductPricesCount() {
  return request({
    url: "productprice-count",
    method: "get",
  });
}
export function sendMail(params) {
  return request({
    url: "sendmail-baogia",
    method: "get",
    params,
  });
}

export function getAllProductPrices(params) {
  return request({
    url: "allproductprice",
    method: "get",
    params,
  });
}

export function getProductPricesById(params) {
  return request({
    url: "getproductpricebyid",
    method: "get",
    params,
  });
}

export function getSourceProductPrice(params) {
  return request({
    url: "productprice-selected",
    method: "get",
    params,
  });
}

export function getSourceProductPriceRequest(params) {
  return request({
    url: "productpricerequest-selected",
    method: "get",
    params,
  });
}

export function addProductPrices(data) {
  return request({
    url: "productprice",
    method: "post",
    data,
  });
}
export function updateStatusProductPrices(id, data) {
  return request({
    url: `/productprice/${id}/update-handler-status`,
    method: "post",
    data,
  });
}

export function editProductPrices(id, data) {
  return request({
    url: `productprice/${id}`,
    method: "post",
    data,
  });
}

export function deleteProductPrices(data) {
  return request({
    url: "/productprice",
    method: "delete",
    data,
  });
}

export function printProductPrices(id, params) {
  return request({
    url: `/productprice-print/${id}`,
    responseType: "blob",
    params,
  });
}

export function printProductPriceCompetitor(id, params) {
  return request({
    url: `/productprice-competitor-print/${id}`,
    responseType: "blob",
    params,
  });
}

export function exportProductPrices(params) {
  return request({
    url: `/productprice-export`,
    responseType: "blob",
    params,
  });
}
export function exportProductPricesTotal(params) {
  return request({
    url: `/productprice-total-export`,
    responseType: "blob",
    params,
  });
}
export function report() {
  return request({
    url: "productprice-report",
    method: "get",
  });
}
