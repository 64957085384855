import * as Toast from "@/utils/notify-show";

const state = {};

const mutations = {};


const actions = {
  info({ commit }, message = "") {
    Toast.info(message, 2000);
  },
  success({ commit }, message = "") {
    Toast.success(message, 2000);
  },
  warning({ commit }, message = "") {
    Toast.warning(message, 2000);
  },
  error({ commit }, message = "") {
    Toast.error(message, 2000);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
