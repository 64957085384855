import Vue from "vue";
import App from "./App.vue";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import * as VueGoogleMaps from "vue2-google-maps";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueRouter from "vue-router";
import router from "./router/permission";
import "./app.css";
import confirmBoxPlugin from "./plugins/confirmBox";
import PageListLayout from "./components/Layout/PageListLayout.vue";
import CustomTable from "./components/custom-table.vue";
import CustomInput from "./components/custom-input.vue";
import Pagination from "./components/pagination.vue";
import ImageUpdate from "@/components/upload/ImageUpload";
import UploadFile from "@/components/upload/UploadFile";
import FormDialog from "@/components/form-dialog";
import DateTimePicker from "@/components/Date/date-time-picker";
import MapPicker from "@/components/map/map-picker";
import VueSignature from "vue-signature-pad";

import "./plugins/vuetify-mask.js";
//apexchart
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.use(confirmBoxPlugin, { store });
Vue.use(Toast);
Vue.use(VueSignature);
Vue.use(VueRouter);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg",
  },
});
Vue.component("PageListLayout", PageListLayout);
Vue.component("CustomTable", CustomTable);
Vue.component("CustomInput", CustomInput);
Vue.component("Pagination", Pagination);
Vue.component("ImageUpdate", ImageUpdate);
Vue.component("UploadFile", UploadFile);
Vue.component("FormDialog", FormDialog);
Vue.component("DateTimePicker", DateTimePicker);
Vue.component("MapPicker", MapPicker);


Object.byString = function(o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1");
  s = s.replace(/^\./, "");
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  vuetify,
  render: (h) => h(App),
  store,
});
