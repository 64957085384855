<template>
  <v-autocomplete
    v-model="model"
    v-bind="$attr"
    :prepend-inner-icon="prependInnerIcon"
    :item-text="itemText"
    :item-value="itemValue"
    :items="items"
    outlined
    dense
    :loading="loading"
    :placeholder="placeholder"
    :search-input.sync="search"
    v-on="$listeners"
    @click:clear="clear"
  >
    <template #no-data>
      <v-list-item>
        <v-list-item-title v-if="isLoading"> Tìm kiếm... </v-list-item-title>
        <v-list-item-title v-else>
          Không có dữ liệu thỏa mãn
        </v-list-item-title>
      </v-list-item>
    </template>
    <template #append-item>
      <div>
        <h4>Slot appen-item</h4>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    required: Boolean,
    loading: Boolean,
    disabled: Boolean,
    placeholder: { type: String, default: "Type to search" },
    itemText: { type: [String, Function], default: "name" },

    prependInnerIcon: { type: String },
    value: {},
  },
  data: () => ({
    items: [],
    isLoading: false,
    search: null,
    paginate: {},
    p_model: null,
  }),

  computed: {
    model: {
      //   get() {
      //     return this.value || this.p_model;
      //   },
      //   set(value) {
      //     this.p_model = value;
      //     this.$emit("input", value);
      //     this.$emit("select:item", value);
      //   },
    },
  },
  created() {
    this.getData();
  },
  watch: {
    // value: {
    //   handler(value) {
    //     if (!value) {
    //       this.search = null;
    //       this.p_model = null;
    //     } else {
    //       if (typeof value == "object") {
    //         this.p_model = value;
    //         if (this.items.length < 1) this.items = [value];
    //       }
    //     }
    //   },
    //   immediate: true,
    // },
    // search: debounce(function(newVal) {
    //   this.getData(newVal);
    // }, 200),
    // params: {
    //   handler: debounce(function(newVal) {
    //     this.getData(this.search);
    //   }, 200),
    //   deep: true,
    // },
  },
  methods: {
    getText(value) {
      //   if (isFunction(this.itemText)) {
      //     return this.itemText(value);
      //   //   }
      //   return get(value, this.itemText);
    },
    onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (
        !this.isLoading &&
        this.paginate.total > this.paginate.page * this.paginate.perpage
      ) {
        this.getData(this.search, this.paginate.page + 1);
      }
    },
    async getData(newVal, page = 1) {
      if (!this.url) return;
      if (this.disabled && this.items.length > 0) return;
      //   if (this.p_model && this.getText(this.p_model) === newVal) return;
      if (this.isLoading) return;
      if (
        this.noPaginate &&
        !this.reCallWhenChangeSearch &&
        this.items &&
        this.items.length > 0
      )
        return;
      try {
        this.isLoading = true;
        this.paginate.page = page;
        const params = this.noPaginate
          ? { limit: this.perpage, paginate: false }
          : { itemPerPage: this.perpage, paginate: true, page };
        // let res = await sdk.get(
        //   this.url,
        //   {
        //     params: { ...this.params, ...params, [this.paramSearch]: newVal },
        //   },
        //   {}
        // );
        // let items = [];
        // let pagination = { total: 0, perpage: 5, page: 1 };
        // if (this.convertData) {
        //   let data = this.convertData(res);
        //   items = data.items;
        //   pagination = data.pagination;
        // } else {
        //   let data = res.data;
        //   items = data.list;
        //   pagination = data.pagination;
        // }
        // this.paginate = pagination;
        // if (parseInt(this.paginate.page) == 1) {
        //   this.items = items;
        // } else {
        //   this.items = this.items.concat(items);
        // }
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    clear() {
      this.model = undefined;
      this.$emit("click:clear");
      this.getData();
    },
  },
};
</script>

<style scoped></style>
