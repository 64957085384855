import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vue from "vue";
Vue.use(Toast);

export function info(message, timeout) {
  Vue.$toast.info(message, {
    position: "top-center",
    timeout,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
  });
}
export function success(message, timeout = 1000) {
  Vue.$toast.success(message, {
    position: "top-center",
    timeout,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
  });
}
export function error(message, timeout = 4000) {
  Vue.$toast.error(message, {
    position: "top-center",
    timeout,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
  });
}
export function warning(message, timeout = 1500) {
  Vue.$toast.warning(message, {
    position: "top-center",
    timeout,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
  });
}
