var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"box import text-uppercase d-flex justify-center align-center py-2",class:[_vm.drag ? 'drag' : ''],on:{"click":_vm.goImport,"dragover":function($event){_vm.overrideDefault($event);
      _vm.hoverDrag();},"dragenter":function($event){_vm.overrideDefault($event);
      _vm.hoverDrag();},"dragleave":function($event){_vm.overrideDefault($event);
      _vm.hoverDragEnd();},"drop":function($event){_vm.overrideDefault($event);
      _vm.hoverDragEnd();
      _vm.changeFile($event);}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"30"}},[_vm._v("mdi-file-document")]),_vm._v(" "+_vm._s(_vm.title || "Tải hoặc kéo thả tệp tin")+" "),_c('input',{ref:"box_input_file",attrs:{"type":"file","hidden":"","multiple":_vm.multiple,"accept":_vm.accept},on:{"change":_vm.changeFile}})],1),(_vm.files && _vm.files.length > 0)?_c('div',{staticClass:"mt-2 flex-grow-1"},[_c('CustomTable',{attrs:{"headers":_vm.headers,"items":_vm.files,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
      var item = ref.item;
return [_c('div',{attrs:{"title":item.stt}},[_c('v-icon',{attrs:{"size":"40","color":_vm.colorType(item.typeFile)}},[_vm._v(_vm._s(_vm.typeIconHandle(item.typeFile)))])],1)]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[(_vm.edit != item.stt)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-width":"170px"}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-capitalize ml-2",attrs:{"icon":"","height":"22px","width":"22px"},on:{"click":function($event){return _vm.setEdit(item)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Chỉnh sửa")])])],1):_c('div',{staticClass:"d-flex align-center"},[(_vm.edit != item.stt)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v("Chỉnh sửa tên")])]):_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.edit_value),expression:"edit_value"}],ref:"suggestForm",style:(("outline: transparent; max-width: 300px; width: " + (_vm.edit_value.length *
                      8) + "px;")),attrs:{"autofocus":""},domProps:{"value":(_vm.edit_value)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save(item)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancelEdit()}],"input":function($event){if($event.target.composing){ return; }_vm.edit_value=$event.target.value}}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-capitalize",attrs:{"icon":"","height":"22px","width":"22px"},on:{"click":function($event){return _vm.save(item)}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-content-save")])],1)]}}],null,true)},[_c('span',[_vm._v("Lưu")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-capitalize",attrs:{"icon":"","height":"22px","width":"22px"},on:{"click":function($event){return _vm.cancelEdit()}}},on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Huỷ bỏ")])])],1)]),_c('div',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(
              ("<strong>Size:</strong> " + (_vm.formatSize(
                item.size
              )) + " | <strong>Type:</strong> " + (item.typeFile))
            )}})])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasImport),expression:"hasImport"}],class:{ cusor: _vm.isLoadingHorizontal },attrs:{"size":"12","icon":"","loading":_vm.isLoadingHorizontal},on:{"click":function($event){return _vm.confirmImport(item)}}},[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1),_c('v-btn',{attrs:{"size":"12","icon":""},on:{"click":function($event){return _vm.downloadItem(item)}}},[_c('v-icon',[_vm._v("mdi-download-outline")])],1),_c('v-btn',{attrs:{"size":"12","icon":""},on:{"click":function($event){return _vm.removeFile(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }