import service  from "@/utils/request";
import { clone } from "lodash";
import JsonToFromData from "@/utils/JsonToFormData.js";
import { withErrorHandling } from "@/utils/error-handling";

const sdk_request = service
const DEFAULT_OPTION = {
  only: ["list", "getDetail", "create", "update", "destroy"],
  isConvertFormData: false,
  convertData: null,
  url: "",
};
const DEFAULT_METHOD = {
  list(params, option = {}) {
    return this.sdk({
      url: `${this.url}`,
      params,
      ...option,
    });
  },
  getDetail: {
    request(id, option = {}) {
      return this.sdk({
          method: "get",
          url: `${this.url}/${id}`,
          ...option,
        })
        .then((res) => res.data);
    },
    type: "list",
  },
  async create(data, option = {}) {
    data = this.convertData(data, "create");
    const res = await this.sdk({
      method: "post",
      url: `${this.url}`,
      data,
      ...option,
    });
    return res.data;
  },
  async update(id, data, option = {}) {
    if (this.option.isConvertFormData) {
      data = this.convertData({ ...data, _method: "patch" }, "update");
      const res = await this.sdk({
        method: "post",
        url: `${this.url}/${id}`,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        ...option,
      });
      return res.data;
    } else {
      data = this.convertData(data, "update");
      const res = await this.sdk({
        method: "put",
        url: `${this.url}/${id}`,
        data: data,
        ...option,
      });
      return res.data;
    }
  },
  async destroy(id, option = {}) {
    const res = await this.sdk({
      method: "delete",
      url: `${this.url}/${id}`,
      ...option,
    });
    return res.data;
  },
};
export class AApi {
  constructor(option = DEFAULT_OPTION, otherApi = {}, sdk = sdk_request) {
    this.updateOption(option);
    this._url = option.url;
    this.sdk = sdk;
    this._wrapConvertData();
    this._wrapCallWithErrorHandle(otherApi);
  }
  getUrl() {
    return this._url;
  }
  getSdk() {
    return this.sdk;
  }
  clone() {
    return clone(this);
  }
  updateOption(option) {
    this.option = Object.assign(
      {},
      clone(DEFAULT_OPTION),
      this.option || {},
      option
    );
    this.url = option.url;
    this._wrapConvertData();
    return this;
  }
  _wrapConvertData() {
    let defaultConvert = (data) => data;
    if (this.option.isConvertFormData)
      defaultConvert = (data) => {
        return JsonToFromData(data);
      };
    this.convertData = this.option.convertData || defaultConvert;
  }
  _wrapCallWithErrorHandle(otherApi = {}, isCheckOnly = true) {
    const methods = isCheckOnly
      ? [...new Set(this.option.only.concat(Object.keys(otherApi)))]
      : Object.keys(otherApi);
    for (let i = 0, len = methods.length; i < len; i++) {
      const nameMethod = methods[i];
      let { type, request, option } = this._getMethod(
        otherApi[nameMethod],
        nameMethod
      );
      if (!request) continue;
      this[nameMethod] = callWithErrorHandle(this, type, request, option);
    }
  }

  _getMethod(method, nameMethod = "") {
    let other = method || DEFAULT_METHOD[nameMethod];
    if (!other.request) {
      other = {
        request: other,
      };
    }
    return Object.assign(
      {
        request: null,
        option: {},
        type: "list",
        storage: {},
      },
      {
        type: nameMethod,
      },
      other
    );
  }
}

function callWithErrorHandle(instance, type, method, option = {}) {
  return function() {
    const _args = arguments;
    return withErrorHandling(
      method.bind(instance, ..._args),
      type,
      option.handleError,
      option.handleSuccess
    );
  };
}
