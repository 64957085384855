var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{class:{ 'drawer-mini': !_vm.DRAWER_STATE },staticStyle:{"top":"64px !important","height":"calc(100vh - 64px) !important","background-color":"rgb(207, 216, 220)"},attrs:{"app":"","mini-variant":!_vm.DRAWER_STATE,"width":_vm.drawerWidth},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"grey lighten-3"},[(_vm.drawerWidth === _vm.sidebarMinWidth)?[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"width":_vm.sidebarMinWidth,"icon":"","tile":""},on:{"click":_vm.handleDrawerCollapse}},[_c('v-icon',[_vm._v("mdi-arrow-collapse-right")])],1)],1)]:[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","tile":""},on:{"click":_vm.handleDrawerCollapse}},[_c('v-icon',[_vm._v("mdi-arrow-collapse-left")])],1)],1)]],2)]},proxy:true}]),model:{value:(_vm.DRAWER_STATE),callback:function ($$v) {_vm.DRAWER_STATE=$$v},expression:"DRAWER_STATE"}},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":_vm.drawerWidth !== _vm.sidebarMinWidth}},[_vm._l((_vm.menus),function(item,key){return [(item.children && item.children.length > 0)?[_c('v-list-group',{key:key,attrs:{"no-action":"","to":item.path},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(item.icon)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)]},proxy:true}],null,true)},_vm._l((item.children.filter(function (el) { return !el.hidden; })),function(subItem){return _c('v-list-item',{key:subItem.name,class:_vm.drawerWidth === _vm.sidebarMinWidth ? 'pl-4' : '',attrs:{"to":subItem.path}},[(_vm.drawerWidth === _vm.sidebarMinWidth)?[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},domProps:{"textContent":_vm._s(subItem.icon)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(subItem.name))])])],1)]:[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"20"},domProps:{"textContent":_vm._s(subItem.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(subItem.name)}})],1)],1)]],2)}),1)]:_vm._e(),((item.children.length == 0 || !item.children) && !item.hidden)?[_c('v-list-item',{key:key,attrs:{"to":item.path,"active-class":"activeMenu"}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({domProps:{"textContent":_vm._s(item.icon)}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1),(_vm.drawerWidth !== _vm.sidebarMinWidth)?_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1):_vm._e()],1)]:_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }