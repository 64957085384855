<template>
  <v-app-bar flat class="main-header" height="64" fixed color="teal" dark>
    <v-progress-linear
      indeterminate
      color="yellow darken-2"
      style="position: fixed; bottom: 0; left: 0; width: 100%"
      height="4"
      v-show="loadingBar"
    ></v-progress-linear>

    <v-btn icon class="mx-1" @click.stop="TOGGLE_DRAWER">
      <template v-if="DRAWER_STATE">
        <v-icon style="font-size: 28px">mdi-arrow-left</v-icon>
      </template>
      <template v-else>
        <v-icon style="font-size: 28px">mdi-menu</v-icon>
      </template>
    </v-btn>
    <v-toolbar-title>Quản Lý Kinh Doanh</v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- <Search /> -->

    <v-menu offset-y bottom nudge-bottom="10" left>
      <template v-slot:activator="{ on }">
        <v-btn
          @click="
            notificationsBadge ? (notificationsBadge = !notificationsBadge) : ''
          "
          v-on="on"
          style="font-size: 28px"
          icon
          class="mr-2"
        >
          <v-badge
            :value="notificationsBadge"
            color="error"
            content="0"
            overlap
          >
            <v-icon style="font-size: 28px" color="rgba(255, 255, 255, 0.35)"
              >mdi-bell-outline</v-icon
            >
          </v-badge>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in notifications" :key="i">
            <v-list-item-icon class="mr-4 mb-1">
              <v-icon :color="item.color" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-menu min-width="180" offset-y bottom left nudge-bottom="10">
      <template v-slot:activator="{ on }">
        <!-- <v-btn class="mr-0" icon v-bind="attrs" v-on="on">
          <v-icon style="font-size: 28px" :color="config.light.iconColor">mdi-account</v-icon>
        </v-btn>-->
        <v-avatar class="pointer"  v-on="on" color="orange" size="40">
          <img
            v-if="USER && USER.url_image"
            :src="'https://apinhansu.mauxanhcuocsong.vn/' + USER.url_image"
            alt="John"
          />
          <span class="white--text headline" v-else-if="USER && USER.name">{{
            USER.name.charAt(0).toUpperCase()
          }}</span>
          <v-icon v-else dark>mdi-account</v-icon>
        </v-avatar>
      </template>
      <v-list min-width="250px" max-width="280px">
        <div class="d-flex justify-center align-center">
          <v-avatar color="orange" size="60">
            <img
              v-if="USER && USER.url_image"
              :src="'https://apinhansu.mauxanhcuocsong.vn/' + USER.url_image"
              alt="John"
            />
            <span class="white--text headline" v-else-if="USER && USER.name">{{
              USER.name.charAt(0).toUpperCase()
            }}</span>
            <v-icon v-else dark>mdi-account</v-icon>
          </v-avatar>
          <div class="ml-4">
            <div class="grey--text text--darken-3">
              {{ USER ? USER.name : "User" }}
            </div>
            <div style="opacity: 0.8; font-size: 14px">
              {{ USER ? USER.email : "Email@email.com" }}
            </div>
          </div>
        </div>
        <div class="mt-3 mb-3 drop_down_menu">
          <v-list-item-group color="primary">
            <v-list-item v-for="(item, i) in account" :key="i" @click="goToLink(item.link)">
              <v-list-item-icon class="mr-4">
                <v-icon :color="item.color" v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :color="config.light.textColor"
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>

        <div class="d-flex justify-center mb-1">
          <v-btn
            class="text-capitalize"
            width="60%"
            outlined
            color="primary"
            @click="logOut"
          >
            <v-icon left>mdi-logout</v-icon>ĐĂNG XUẤT
          </v-btn>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from "vuex";
import config from "../../config";
import Search from "@/components/Search/Search";

import store from "../../store/index";

export default {
  name: "Header",
  // components: { Search },
  data: () => ({
    config,
    imageEndpoint: process.env.VUE_APP_BASE,
    searchCollapse: true,
    notifications: [
 
    ],
    messages: [

    ],
    account: [
      {
        text: "Tài khoản",
        icon: "mdi-account",
        color: "textColor",
        link: "/users/profile",
      },
    ],
    notificationsBadge: true,
    messageBadge: true,
  }),
  computed: {
    ...mapState(["drawer"]),
    USER() {
      return this.$store.state.User.me;
    },
    loadingBar() {
      return this.$store.state.routerRole.loading;
    },
    DRAWER_STATE: {
      get() {
        return this.drawer;
      },
    },
  },
  created() {},
  methods: {
    ...mapActions(["TOGGLE_DRAWER"]),
    logOut: async () => {
      try {
        await store.dispatch("User/logout");
        location.assign("/login");
      } catch (error) {
        console.log(error);
      }
    },
    goToLink(link){
      this.$router.push({ path: link})
    }
  },
};
</script>
<style scoped>
.drop_down_menu {
  border-bottom: 1px solid #0000004d;
  border-top: 1px solid #0000004d;
}
</style>
<style src="./Header.scss" lang="scss"></style>
