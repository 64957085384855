<template>
  <PageListLayout icon="mdi-laptop" title="Thông tin hệ thống" noFilter>
    <div>
      <v-row>
        <v-col class="d-flex flex-column" col="6">
          <v-row
            class="flex-grow-0"
            v-if="
              dataDashBoard.complaint_information &&
              dataDashBoard.complaint_information.type
            "
          >
            <v-col
              v-for="key in Object.keys(
                dataDashBoard.complaint_information.type
              )"
              :key="key"
              col="3"
            >
              <v-card class="mx-auto pa-4" outlined>
                <h5 style="font-weight: 600">Khiếu nại {{ key }}</h5>
                <div>
                  <strong style="font-size: 2.12rem">{{
                    dataDashBoard.complaint_information.type[key]
                  }}</strong>
                  Khiếu nại
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-card class="pa-4 flex-grow-1 mt-4" outlined>
            <h5 style="font-weight: 600">Xin chào {{ USER.name }}</h5>
            <div class="d-flex align-center mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-10">
                <div>
                  <strong style="font-size: 2.12rem">{{
                    dataDashBoard.complaint_information
                      ? dataDashBoard.complaint_information.total
                      : 0
                  }}</strong>
                  Khiếu nại
                </div>
                <div class="mt-1">
                  <div
                    class="d-flex justify-space-between"
                    v-if="
                      dataDashBoard.complaint_information &&
                      dataDashBoard.complaint_information.status
                    "
                  >
                    <div
                      v-for="key in Object.keys(
                        dataDashBoard.complaint_information.status
                      )"
                      :key="key"
                    >
                      <div class="d-flex align-center">
                        <div
                          style="color: #57b894"
                          class="d-flex align-center mr-1"
                        >
                          <strong>{{ key }}:</strong>
                        </div>
                        <div>
                          <strong>{{
                            dataDashBoard.complaint_information.status[key]
                          }}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <v-btn
                    class="text-capitalize"
                    rounded
                    color="#57B894"
                    small
                    style="color: #fff"
                    @click="goKhieuNai"
                  >
                    Xử lý khiếu nại
                  </v-btn>
                </div>
              </div>
              <div class="ml-auto">
                <v-img :src="pickSW" style="width: 200px"></v-img>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col col="6">
          <v-card class="mx-auto pa-4" outlined>
            <h5 style="font-weight: 600" class="mb-0">
              Số hợp đồng đã ký trong năm
            </h5>
            <ContractDashBoard :data="dataDashBoard.contract_month" />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="6">
          <v-card class="mx-auto pa-4" outlined>
            <h5 style="font-weight: 600">Tổng số khách hàng</h5>
            <div class="d-flex align-center mt-1">
              <div style="width: 200px">
                <strong style="font-size: 2.12rem">{{
                  dataDashBoard.customer ? dataDashBoard.customer.total : 0
                }}</strong>
                Khách hàng
              </div>
              <div class="ml-auto">
                <v-img :src="picKh" style="width: 200px"></v-img>
              </div>
            </div>
            <div class="mt-2">
              <div class="d-flex align-center">
                <div
                  style="width: 200px; color: #57b894"
                  class="d-flex align-center"
                >
                  <v-icon color="#57B894" class="mr-1">mdi-account-tie</v-icon>
                  <strong>Khách hàng thường:</strong>
                </div>
                <div>
                  <strong
                    >{{
                      dataDashBoard.customer
                        ? dataDashBoard.customer["Khách hàng thường"]
                        : 0
                    }}
                    khách hàng</strong
                  >
                </div>
              </div>
              <div class="mt-1 d-flex align-center">
                <div
                  style="width: 200px; color: #57b894"
                  class="d-flex align-center"
                >
                  <v-icon color="#57B894" class="mr-1">mdi-account-tie</v-icon>
                  <strong>Khách trọng tâm:</strong>
                </div>
                <div>
                  <strong
                    >{{
                      dataDashBoard.customer
                        ? dataDashBoard.customer["Khách hàng trọng tâm"]
                        : 0
                    }}
                    khách hàng</strong
                  >
                </div>
              </div>
              <div class="mt-1 d-flex align-center">
                <div
                  style="width: 200px; color: #57b894"
                  class="d-flex align-center"
                >
                  <v-icon color="#57B894" class="mr-1">mdi-account-tie</v-icon>
                  <strong>Khách hàng khác:</strong>
                </div>
                <div>
                  <strong
                    >{{
                      dataDashBoard.customer
                        ? dataDashBoard.customer["Khác"]
                        : 0
                    }}
                    khách hàng</strong
                  >
                </div>
              </div>
            </div>
            <!-- <ApexChart
              height="300"
              type="pie"
              :options="{
                labels: ['Chất lượng', 'Dịch vụ', 'Giá cả', 'abc'],
              }"
              :series="[0, 0, 0, 100]"
            /> -->
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mx-auto pa-4" outlined>
            <h5 style="font-weight: 600">Tỷ trọng theo nhóm khách hàng</h5>
            <ApexChart
              height="205"
              type="pie"
              :options="ratioCustomer.options"
              :series="ratioCustomer.series"
            />
          </v-card>
        </v-col>
        <v-col cols="4">
          <!-- <v-card class="mx-auto pa-4" outlined>
            <h5 style="font-weight: 600">Tổng số hợp đồng trong tháng</h5>
          </v-card> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card class="mx-auto pa-4" outlined>
            <h5 style="font-weight: 600" class="mb-0">
              Biểu đồ cột số lượng báo giá trong năm
            </h5>
            <BaogiaChart />
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="mx-auto pa-4" outlined>
            <h5 style="font-weight: 600" class="mb-0">
              Biểu đồ hợp đồng theo tháng trong năm
            </h5>
            <HopdongChart />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mx-auto pa-4" outlined>
            <v-row>
              <v-col cols="5">
                <h5 style="font-weight: 600" class="mb-0">
                  Biểu đồ nhân viên - khu vực - khách hàng theo tháng
                </h5>
              </v-col>
              <v-col cols="2">
                <div class="mt-2">
                  <v-autocomplete
                    :items="this.yearList"
                    v-model="formcus.year"
                    placeholder="Chọn năm"
                    item-text="name"
                    item-value="id"
                    solo
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="mt-2">
                  <v-autocomplete
                    :items="this.monthList"
                    v-model="formcus.month"
                    placeholder="Chọn tháng"
                    item-text="name"
                    item-value="id"
                    solo
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="2">
                <div class="mt-2">
                  <v-autocomplete
                    :items="this.areaList"
                    v-model="formcus.area"
                    placeholder="Chọn khu vực"
                    item-text="name"
                    item-value="id"
                    solo
                    dark
                    color="rgb(255, 255, 255)"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="1">
                <div class="mt-2 mr-5">
                  <v-btn color="success" :loading="btnloading" @click="reset()"> Reset </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <Khachhang refs="khachhang" @on-done="onDone" :filter="this.filter" />
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </PageListLayout>
</template>

<script>
import { monthList } from "@/constants/time";
import { generateArrayOfYears } from "@/utils/date.js";
import { getAllAreas } from "@/api/khuvuc";
import mock from "./mock";
import picKh from "@/assets/images/undraw_kh.svg";
import pickSW from "@/assets/images/undraw_shared_workspace_hwky.svg";
import API from "@/api/dashboard.api.js";
import Trend from "vuetrend";
import ApexChart from "vue-apexcharts";
import { statusList } from "@/constants/khieunai";
import ContractDashBoard from "./ContractDashboard";
import BaogiaChart from "./ChartRequest/Baogia.vue";
import HopdongChart from "./ChartRequest/Hopdong.vue";
import Khachhang from "./ChartRequest/khachhang.vue";
export default {
  name: "Dashboard",
  components: {
    ApexChart,
    ContractDashBoard,
    BaogiaChart,
    HopdongChart,
    Khachhang,
  },
  data() {
    return {
      formcus: {
        month: new Date().getMonth() + 1,
        year: new Date().getUTCFullYear(),
        area: null,
      },
      yearList: generateArrayOfYears(10),
      monthList,
      filter: {},
      btnloading:false,
      areaList: [],
      statusList,
      dataDashBoard: {},
      picKh,
      pickSW,
      picRandom: Math.floor(Math.random() * 6),
      mock,
      apexLoading: false,
      value: this.getRandomInt(10, 90),
      value2: this.getRandomInt(10, 90),
      mainApexAreaSelect: "Daily",
      headers: [
        { text: "Name", value: "name" },
        { text: "Japan Name", value: "japanName" },
        { text: "Ngày sinh", value: "birthday" },
        { text: "V-1", value: "bust" },
        { text: "V-2", value: "waist" },
        { text: "V-3", value: "hip" },
        { text: "Chiều cao", value: "height" },
        { text: "Nhóm máu", value: "blood_type" },
        { text: "Sở thích", value: "hobby" },
        { text: "Quận, thành phố", value: "prefectures" },
      ],
      ages: [],
      ratioCustomer: {
        options: { labels: [] },
        series: [],
      },
    };
  },
  computed: {
    USER() {
      return this.$store.state.User.me;
    },
  },
  created() {
    this.fetchData();
    this.getAllArea();
  },
  methods: {
    async fetchData() {
      const res = await API.getDashBoardData();
      this.dataDashBoard = res.data.data;
      if (this.dataDashBoard.customer) {
        let customer = { ...this.dataDashBoard.customer };
        Object.keys(customer).forEach((key) => {
          if (key != "total") {
            this.ratioCustomer.options.labels.push(key);
            let value = customer[key] / customer["total"];
            this.ratioCustomer.series.push(value);
          }
        });
      }
    },
    getRandomDataForTrends() {
      const arr = [];
      for (let i = 0; i < 12; i += 1) {
        arr.push(Math.random().toFixed(1) * 10);
      }
      return arr;
    },
    generatePieSeries() {
      let series = [];

      for (let i = 0; i < 4; i++) {
        let y = Math.floor(Math.random() * (500 - 100 + 100)) + 100;
        series.push(y);
      }
      return series;
    },
    getRandomInt(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },
    goKhieuNai() {
      this.$router.push({ path: "/quanlykhieunai" });
    },
    Change() {
      this.filter = this.formcus;
    },
    async getAllArea() {
      let data = await getAllAreas();
      this.areaList = data;
    },
    reset() {
      this.btnloading = true;
      this.formcus= {
        month: new Date().getMonth() + 1,
        year: new Date().getUTCFullYear(),
        area: null,
      }
    },
    onDone(){
      this.btnloading = false;

    }
  },
  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
    });
  },
  watch: {
    formcus: {
      handler: function () {
        this.filter = this.formcus;
      },
      deep: true,
    },
  },
};
</script>

<style src="./Dashboard.scss" lang="scss" />
