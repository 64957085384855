import * as notify from "@/utils/notify-show";
const DEFAULT_STRING_ERROR_TYPE = {
  list: "Lỗi xảy ra khi lấy dữ liệu",
  getDetail: "Lỗi lấy dữ liệu",
  create: "Thêm mới thất bại",
  add: "Thêm mới thất bại",
  update: "Cập nhật thất bại",
  destroy: "Xoá thất bại",
  delete: "Xoá thất bại",
};
const DEFAULT_STRING_SUCCESS_TYPE = {
  create: "Thêm mới thành công",
  add: "Thêm mới thành công",
  update: "Cập nhật thành công",
  destroy: "Xoá thành công",
  delete: "Xoá thành công",
};

/**
 * @async
 * @function withErrorHandling
 * @param {Function} cb callback use.
 * @param {'create'|'destroy'|'update'|'list'|'getDetail'} type type handlding.
 * @param {string|defaultHanlderError} handleError key string for error or function use for handle error.
 * @param {string | defaultHanlderSuccess} handleSuccess key string for success or function use for handle success.
 * @returns {object} data return
 */
export async function withErrorHandling(cb, type, handleError, handleSuccess) {
  try {
    const res = await cb();
    return handler(res, handleSuccess, type, {
      handle: defaultHandlerSuccess,
      message: DEFAULT_STRING_SUCCESS_TYPE[type],
    });
  } catch (error) {
    return handler(error, handleError, type, {
      handle: defaultHandlerError,
      message: DEFAULT_STRING_ERROR_TYPE[type],
    });
  }
}

function handler(data, customHandle, type, { handle, message }) {
  return !customHandle || typeof customHandle == "string"
    ? handle(data, customHandle || message, type)
    : customHandle(data, handle.bind(null, data, message, type));
}

/**
 * @function defaultHanlderSuccess
 * @callback defaultHanlderSuccess
 * @param {object} res Response from callback
 * @param {string} messageSuccess  message show for notify.
 * @param {'create'|'destroy'|'update'|'list'|'getDetail'} type type handlding.
 * @returns {object} Response from callback
 */
export function defaultHandlerSuccess(res, messageSuccess, type) {
  if (messageSuccess) {
    notify.success(messageSuccess);
    return { isDone: true, data: res };
  }
  if (type == "list") {
    return res;
  }
  if (res.message) notify.success(res.message);
  return { isDone: true, data: res };
}

/**
 * @function defaultHanlderError
 * @callback defaultHanlderError
 * @param {object} error error from callback
 * @param {string} messageError  message show for notify.
 * @param {'create'|'destroy'|'update'|'list'|'getDetail'} type type handlding.
 * @returns {boolean} return false
 */
export function defaultHandlerError(error, messageError, type) {
  let { response } = error;
  if (response && ![401, 403].includes(response.status)) {
    const { data } = response;
    if (data.hint && data.hint.key) {
      notify.error(messageError);
    } else if (response.status == 422) {
      let err = data.errors;
      notify.error(
        Object.keys(err)
          .map((key) => err[key])
          .flat()
          .join("\n")
      );
    } else {
      notify.error(
        response.data.message ? response.data.message : messageError
      );
    }
  }
  return { isDone: false, error };
}
