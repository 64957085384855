<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="valueInput"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        :readonly="inputReadonly && !readonly"
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        :hide-details="hideDetails"
        :placeholder="placeholder"
        :rules="rules"
        @keypress="handleKeyPress"
        :loading="loading"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="menu = false"
      :readonly="readonly"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import moment from "moment";
export default {
  props: {
    value: String,
    label: { type: String, default: "" },
    placeholder: String,
    rules: { type: Array, default: () => [] },
    readonly: Boolean,
    hideDetails: { type: [Boolean, String], default: true },
    format: { type: String, default: "YYYY-MM-DD" },
    inputReadonly: { type: Boolean, default: true },
    loading: Boolean,
  },
  data: (vm) => ({
    menu: false,
    valueInput: "",
    formatDateValue: "YYYY-MM-DD",
  }),
  computed: {
    date: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
  created(){
    this.valueInput = this.handleValue(this.date)
  },
  watch: {
    valueInput(val) {
      if (
        moment(val, this.format, true).isValid() ||
        moment(val, this.format, true).isValid()
      ) {
        this.date = moment(val, this.format, true).format(this.formatDateValue);
      }
    },
    date(val) {
      this.valueInput = this.handleValue(val);
    },
  },
  methods: {
    handleValue(value) {
      return value
        ? moment(value, this.formatDateValue, true).format(this.format)
        : "";
    },
    handleKeyPress(event) {
      let regex = new RegExp("^[0-9//]+$");
      let charCode = event.key;
      if (!regex.test(charCode)) {
        event.preventDefault();
        return false;
      }
    },
  },
};
</script>