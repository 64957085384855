<template>
  <div>
    <ApexChart
      :key="keyChange"
      type="bar"
      height="300"
      :options="chartOptions"
      :series="series"
    ></ApexChart>
  </div>
</template>
<script>
import ApexChart from "vue-apexcharts";
export default {
  props: {
    data: { type: Array, default: () => Array(12).fill(0) },
  },
  components: {
    ApexChart,
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        if (val.length == 12 && !val.some((x) => isNaN(x))) {
          this.$set(this.series[0], "data", [...val]);
          this.keyChange++;
        }
      },
    },
  },
  data: (vm) => ({
    keyChange: 1,
    series: [
      {
        name: "Số hợp đồng",
        data: vm.data,
      },
    ],
    chartOptions: {
      colors: ["#57b894"],
      chart: {
        height: 300,
        type: "bar",
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: [
          "Thg 1",
          "Thg 2",
          "Thg 3",
          "Thg 4",
          "Thg 5",
          "Thg 6",
          "Thg 7",
          "Thg 8",
          "Thg 9",
          "Thg 10",
          "Thg 11",
          "Thg 12",
        ],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: `Số hợp đồng hàng tháng, ${new Date().getFullYear()}`,
        floating: true,
        offsetY: 280,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  }),
};
</script>