import axios from "axios";
import store from "@/store";
import { getToken, setToken, removeToken } from "@/utils/auth";
import * as notify from "@/utils/notify-show";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000000,
});

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// Trước request
const ignoreSpecifiedUrl = [
  "sourceproductpricerequest",
  "allproductprice",
  "productprice-selected",
  "productpricerequest-selected",
  "productprice",
  "productDetailPriceHistory",
];
service.interceptors.request.use(
  (config) => {
    if (
      !ignoreSpecifiedUrl.includes(config.url) &&
      config.params?.id_product_details === undefined
    ) {
      store.dispatch("loading/setLoadingTrue");
    }
    config.headers["Authorization"] = "Bearer " + getToken();
    config.mode = "no-cors";
    return config;
  },
  (error) => {
    store.dispatch("loading/setLoadingFalse");
    return Promise.reject(error);
  }
);

// Sau request response
service.interceptors.response.use(
  (response) => {
    store.dispatch("loading/setLoadingFalse");
    const res = response.data;
    return res;
  },
  async (error) => {
    store.dispatch("loading/setLoadingFalse");
    if (error.response.data.code == "token_expire") {
      let result = await service.post("/auth/refresh"); //Refresh token khi hết hạn
      setToken(result.access_token);
      error.response.headers["Authorization"] = "Bearer " + getToken();
      return service.request(error.response.config);
    } else if (
      error.response.data.code == "token_not_found" ||
      error.response.data.code == "unauthorized"
    ) {
      removeToken();
      location.assign("/login");
    } else {
      if (
        error.response.status == 401 ||
        error.response.status == 403 ||
        error.response.status == 404
      ) {
        notify.error(error.response.data.message);
      }
      Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default service;
