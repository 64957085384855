import request from "../utils/request";
import { AApi } from "@/service/AApi";
const API = new AApi(
  {},
  {
    getDashBoardData: {
      async request(params) {
        return this.sdk({
          url: "getDashBoardData",
          method: "get",
          params,
        });
      },
    },
    Baogia: {
      async request() {
        return this.sdk({
          url: "dashboad-baogia",
          method: "get",
        });
      },
    },
  }

)
export default API;

// export function getDashBoardData(params) {
//   return request({
//     url: "getDashBoardData",
//     method: "get",
//     params,
//   });
// }
