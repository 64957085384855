export const statusList = [
  { id: 1, name: "Đã xử lý" },
  { id: 2, name: "không xử lý được" },
];
export const styleStatus = {
  1: "bg-success",
  2: "bg-danger",
};
export const channels = [
  {
    id: 1,
    name: "Email",
  },
  {
    id: 2,
    name: "Điện thoại",
  },
  {
    id: 3,
    name: "Văn bản",
  },
];
export const listType = [
  {
    id: 1,
    name: "Xe vận chuyển",
  },
  {
    id: 2,
    name: "Công nhân",
  },
  {
    id: 3,
    name: "Hàng hoá",
  },
  {
    id: 4,
    name: "Công nợ",
  },
  {
    id: 5,
    name: "Khiếu nại khác",
  },
];
