<template>
  <div class="container">
    <apexchart
      type="line"
      height="500"
      :key="`chart-key-${key}`"
      :options="chartOptions"
      :series="series"
    >
    </apexchart>
  </div>
</template>
<script>
import Api from "@/api/khachhang.js";
export default {
  props: ["filter"],
  data() {
    return {
      key: 0,
      data: {},
      name: [],
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "line",
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: "Biểu đồ theo thời gian",
          align: "center",
          style: {
            fontSize: "15px",
            fontWeight: "bold",
            fontFamily: "Arial",
            color: "#263238",
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [],
        xaxis: {
          type: "string",
        },
        yaxis: [
          {
            title: {
              text: "Tổng khách hàng",
            },
          },
          {
            opposite: true,
            title: {
              text: "khách hàng mới",
            },
          },
        ],
      },
    };
  },
  mounted() {
    let current = {
      year: new Date().getUTCFullYear(),
      month: new Date().getMonth() + 1,
    };
    this.hanlde_getdata(current);
  },
  methods: {
    async hanlde_getdata(data) {
      const res = await Api.report(data);
      this.data = res.data;
      this.handle_render_data(this.data);
    },
    handle_render_data() {
      let customer_total = [];
      let customer_new = [];

      this.series = [];
      this.chartOptions.labels = [];
      this.data.forEach((e) => {
        if (e.staff.trim() === "KH Trọn gói") {
          this.chartOptions.labels.push("Trọn gói" + "-" + e.area[0].code);
        } else {
          this.chartOptions.labels.push(
            e.staff.split(" ").slice(-1).join(" ") + "-" + e.area[0].code
          );
        }
        customer_total.push(e.area[0].customers_total);
        customer_new.push(e.area[0].customers_new);
      });
      this.series.push(
        {
          name: "Tổng khách hàng",
          type: "column",
          data: customer_total,
        },
        {
          name: "Khách hàng mới",
          type: "line",
          data: customer_new,
        }
      );
      this.key++;
      this.$emit("on-done");
    },
  },
  watch: {
    filter: {
      handler: function () {
        this.hanlde_getdata(this.filter);
      },
      deep: true,
    },
  },
};
</script>

<style>
.container {
  margin-top: 10px;
  width: 100%;
}
</style>
