<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :loading="loading"
      :hide-default-footer="hideDefaultFooter"
      :loading-text="loadingText"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      @click:row="handleClick"
      :hide-default-header="hideDefaultHeader"
      :show-select="showSelect"
      :show-expand="showExpand"
      :single-expand="singleExpand"
      :expanded.sync="expandedValue"
      @toggle-select-all="handleSelecedAll"
    >
      <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
        <slot v-if="item" :name="name" v-bind="item"></slot>
        <slot v-else :name="name"></slot>
      </template>
      <template slot="no-data">
        <div class="text-center">Không có dữ liệu</div>
      </template>
    </v-data-table>
    <div :class="classPaging" v-if="numberOfPage && numberOfPage > 1">
      <Pagination
        v-model="current_page"
        :pageCount="numberOfPage"
        @change-page="changePage"
        :total-visible="10"
      >
      </Pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    loading: Boolean,
    hideDefaultFooter: Boolean,
    loadingText: String,
    itemsPerPage: { type: Number, default: 10 },
    page: { type: Number, default: 1 },
    pageCount: Number,
    classPaging: String,
    hideDefaultHeader: Boolean,
    showSelect: Boolean,
    showExpand: Boolean,
    singleExpand: Boolean,
    value: Array,
    expanded: { type: Array, default: () => [] },
  },
  data: () => ({
    current_page: 1,
  }),
  mounted() {},
  computed: {
    numberOfPage() {
      if (this.pageCount) {
        return this.pageCount;
      }
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    slot() {
      return this.$scopedSlots;
    },
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    expandedValue: {
      get() {
        return this.expanded;
      },
      set(val) {
        this.$emit("expanded", val);
      },
    },
  },
  watch: {
    page(val) {
      this.current_page = val;
    },
  },
  methods: {
    handleClick(e) {
      this.$emit("click:row", e);
    },
    changePage(e) {
      this.$emit("change-page", e);
    },
    handleSelecedAll(e){
      this.$emit("toggle-select-all", e);
    }
  },
};
</script>
<style scoped>
/deep/ .v-pagination button {
  box-shadow: none !important;
  outline: 0 !important;
}
/deep/ .v-data-table .v-data-table__wrapper {
  border-radius: 4px;
  border: 1px solid #bdc6cc !important;
}
/deep/ .v-data-table table th {
  color: #000 !important;
  font-size: 0.825rem !important;
}
/deep/.v-data-table table {
  border: none !important;
}
/deep/.v-data-table table th {
  background-color: #dde0e8;
}
</style>