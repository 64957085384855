const state = {
  loading: false,
};
const getters = {
  loading: (state) => state.loading,
};
const actions = {
  setLoadingTrue() {
    state.loading = true;
  },
  setLoadingFalse() {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
};
