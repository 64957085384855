<template>
  <v-dialog v-model="show" persistent :max-width="maxWidth" :width="width">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-form ref="form_dialog" @submit="submitForm">
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
          <v-btn
            v-if="!readonly"
            type="submit"
            :loading="btnLoading"
            color="primary"
            @click="submitForm"
          >
            {{ textButtonSubmit }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    value: Boolean,
    btnLoading: Boolean,
    textButtonSubmit: { type: String, default: "Lưu" },
    maxWidth: String,
    width: String,
    readonly: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.$refs.form_dialog.validate();
      if (this.$refs.form_dialog.validate()) {
        this.$emit("submit");
      }
    },
  },
};
</script>
