import request from "../utils/request";

export function exportContractsByRoles() {
  return request({
    url: "contract-export-by-roles",
    method: "get",
    responseType: "blob"
  });
}

export function getListReceivedContracts(id) {
  return request({
    url: `list-received-contracts/${id}`,
    method: "get",
  });
}

export function getContracts(params) {
  return request({
    url: "contracts",
    method: "get",
    params,
  });
}

export function getAllContracts() {
  return request({
    url: "allcontracts",
    method: "get",
  });
}

export function getContractsById(params) {
  return request({
    url: "contractsbyid",
    method: "get",
    params,
  });
}

export function addContracts(data) {
  return request({
    url: "contracts",
    method: "post",
    data,
  });
}

export function editContracts(data) {
  return request({
    url: "contracts",
    method: "put",
    data,
  });
}

export function deleteContracts(data) {
  return request({
    url: "/contracts",
    method: "delete",
    data,
  });
}

export function addContractAddendum(data) {
  return request({
    url: "/contract-addendum",
    method: "post",
    data,
  });
}
export function updateContractAddendum(id, data) {
  return request({
    url: `/contract-addendum/${id}`,
    method: "post",
    data,
  });
}
export function printContract(id, params) {
  return request({
    url: `/contract-print/${id}`,
    params,
    responseType: "blob",
  });
}
export function printContractAddendum(id, params) {
  return request({
    url: `/contract-addendum-print/${id}`,
    params,
    responseType: "blob",
  });
}
export function resignContract(id){
  return request({
    url: `/contract-resign/${id}`
  })
}
export function syncExtend(){
  return request({
    url: `/contract-update-extend`
  })
}
export function updateFollow(id, data){
  return request({
    url: `/contract-update-follow/${id}`,
    method: "put",
    data,
  })
}
export function exportContractTH(params) {
  return request({
    url: `/contract-export-total`,
    params,
    responseType: "blob",
  });
} 
export function report() {
  return request({
    url: "contracts-report",
    method: "get",
  });
}