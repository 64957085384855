import request from "../utils/request";

export function getAreas(params) {
  return request({
    url: "area-officals",
    method: "get",
    params,
  });
}

export function getAllAreas() {
  return request({
    url: "area-officals",
    method: "get",
  });
}
export function getAllDistrictDepend(province_id) {
  return request({
    url: `areas-all-district/${province_id}`,
    method: "get",
  });
}

export function addAreas(data) {
  return request({
    url: "area-officals",
    method: "post",
    data,
  });
}

export function editAreas(id, data) {
  return request({
    url: `area-officals/${id}`,
    method: "put",
    data,
  });
}

export function deleteAreas(id) {
  return request({
    url: `/area-officals/${id}`,
    method: "delete",
  });
}
