export function formatDate(e) {
  try {
    if (!e) {
      return "";
    }
    let date = new Date(e);
    let ngay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let thang =
      Number(date.getMonth() + 1) < 10
        ? "0" + Number(date.getMonth() + 1)
        : Number(date.getMonth() + 1);
    return ngay + "/" + thang + "/" + date.getFullYear();
  } catch (error) {
    return "";
  }
}
export function generateArrayOfYears(range = 9) {
  var max = new Date().getFullYear()
  var min = max - range
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}
