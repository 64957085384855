export default function(object) {
  var form_data = new FormData();
  for (var key in object) {
    form_data = handleFormData(form_data, object[key], key, null);
  }
  // for (var value of form_data.values()) {
  //   console.log(value);
  // }
  return form_data;
}

function handleFormData(
  formData = new FormData(),
  value,
  key,
  keyArrayObject = null
) {
  if (checkArray(value)) {
    value.forEach((element, index) => {
      let key_in = `${key}[${index}]`;
      formData = hasKeyArrayObject(formData, element, key_in, keyArrayObject);
    });
    if(value.length == 0){
      formData.append(key, []);
    }
  } else if (checkObject(value)) {
    Object.keys(value).forEach((e_key) => {
      formData = hasKeyArrayObject(
        formData,
        value[e_key],
        `${key}[${e_key}]`,
        keyArrayObject
      );
    });
  } else if (checkNotEmpty(value)) {
    formData.append(key, value);
  }
  return formData;
}

function checkNotEmpty(value) {
  return value || value == 0;
}
function checkObject(value) {
  return (
    typeof value === "object" && value !== null && !(value instanceof File)
  );
}
function checkFile(value) {
  return value instanceof File;
}
function checkArray(value) {
  return Array.isArray(value);
}
function hasKeyArrayObject(formData, value, key, keyArrayObject) {
  if (keyArrayObject) {
    formData = handleFormData(
      formData,
      value[keyArrayObject],
      key,
      keyArrayObject
    );
  }
  formData = handleFormData(formData, value, key, keyArrayObject);
  return formData;
}
