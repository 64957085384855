import { render, staticRenderFns } from "./ContractDashboard.vue?vue&type=template&id=b6e07572&"
import script from "./ContractDashboard.vue?vue&type=script&lang=js&"
export * from "./ContractDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports