<template>
  <div class="d-flex justify-center">
    <v-pagination
      v-model="current_page"
      :length="pageCount"
      @input="changePage"
      :total-visible="10"
      >></v-pagination
    >
    <div class="input__field" v-if="pageCount > 1 && !hidePage">
      <v-text-field
        v-model="page_input"
        append-icon="mdi-chevron-right"
        @click:append="goPage(page_input)"
        @keydown.native.enter="goPage(page_input)"
        dense
        outlined
        hide-details
        label="Đi tới"
        type="number"
        :min="value"
        :max="pageCount"
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    value: {type: Number, default: 1},
    pageCount: {type: Number, default: 1},
    hidePage: Boolean
  },
  data: () => ({
    page_input: 0,
  }),
  computed: {
    current_page: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    this.page_input = this.value ? this.value : 0;
  },
  methods: {
    changePage(e) {
      this.$emit("change-page", e);
    },
    goPage(number) {
      number = parseInt(number)
      if (!isNaN(number) && number >= 1 && number <= this.pageCount && Number.isInteger(number)) {
        this.current_page = number;
        this.$emit("change-page", number);
      }
    },
  },
};
</script>
<style scoped>
/deep/ .v-pagination button {
  box-shadow: none !important;
  outline: 0 !important;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/deep/ input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<style lang="scss" scoped>
.input__field {
  width: 100px;
}
</style>