<template>
  <div id="app">
    <router-view />
    <comfirm-box></comfirm-box>
    <v-overlay :opacity="0.3" :value="isLoading" style="z-index: 99999">
      <loading-bar />
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    ComfirmBox: () => import("@/components/ConfirmBox/ComfirmBox.vue"),
    loadingBar: () => import("@/components/loading-bar.vue"),
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/loading",
    }),
  },
};
</script>
