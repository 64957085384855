import request from "../utils/request";
import axios from "axios";
import { AApi } from "@/service/AApi";
const API = new AApi(
  {
    url: "customers",
    only: ["list", "getDetail", "create", "update"],
  },
  {
    getAllCustomers: {
      async request(params) {
        return this.sdk({
          url: "/allcustomers",
          params,
        });
      },
    },
    getAllActiveCustomers: {
      async request(params) {
        return this.sdk({
          url: "/allactivecustomers",
          params,
        });
      },
    },
    importCustomers: {
      async request(data) {
        return this.sdk({
          url: "importcustomers",
          method: "post",
          data,
        });
      },
      option: {
        handleError: "Lỗi import",
        handleSuccess: "Thêm dữ liệu thành công",
      },
    },
    exportCustomer: {
      async request(params) {
        return this.sdk({
          url: "customers-export",
          params,
          responseType: "blob",
        });
      },
    },
    mergeCustomers: {
      async request(data) {
        return this.sdk({
          url: "customer/merge-records",
          method: "put",
          data,
        });
      },
    },
    updateActiveCustomers: {
      async request(data) {
        return this.sdk({
          url: "customers-active",
          method: "put",
          data,
        });
      },
    },
    custommerAction: {
      async request(data) {
        return this.sdk({
          url: "open-customers-active",
          method: "put",
          data,
        });
      },
    },
    getCustomerStatus: {
      async request() {
        return this.sdk({
          url: "customer-status",
        });
      },
    },
    report: {
      async request(params) {
        return this.sdk({
          url: "customers-report",
          method: "get",
          params,
        });
      },
    },
    searchInfoByTax: {
      async request(tax) {
        const res = await axios.get(`https://api.vietqr.io/v2/business/${tax}`);
        return res.data.data;
      },
    },
  }
);
export default API;
// export function getCustomers(params) {
//     return request({
//         url: "customers",
//         method: "get",
//         params,
//     });
// }
// export function show(id){
//     return request({
//         url: `customers/${id}`,
//         method: "get",
//     });
// }

// export function addCustomers(data) {
//     return request(
//         {
//             url: "customers",
//             method: "post",
//             data,
//         }
//     );
// }

// export function editCustomers(data) {
//     return request({
//         url: "customers",
//         method: "put",
//         data,
//     });
// }

// export function importCustomers(data) {
//   return request({
//     url: "importcustomers",
//     method: "post",
//     data,
//   });
// }

// export function exportCustomer(params) {
//   return request({
//     url: "customers-export",
//     params,
//     responseType: "blob",
//   });
// }

// export function updateActiveCustomers(data) {
//   return request({
//     url: "customers-active",
//     method: "put",
//     data,
//   });
// }
// export function custommerAction(data) {
//   return request({
//     url: "open-customers-active",
//     method: "put",
//     data,
//   });
// }

// export function getCustomerStatus() {
//   return request({
//     url: "customer-status",
//   });
// }
