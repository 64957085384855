import request from "../utils/request";

export function update(id, data) {
  return request({
    url: `documents/${id}`,
    method: "patch",
    data,
  });
}
export function updateWithFile(id, data) {
  return request({
    url: `documents/${id}`,
    method: "post",
    data,
  });
}
export function store(data){
  return request({
    url: `documents`,
    method: "post",
    data
  });
}
export function destroy(id) {
  return request({
    url: `documents/${id}`,
    method: "delete",
  });
}
export function multipleStore(data) {
  return request({
    url: `upload-multiple-documents`,
    method: "post",
    data
  });
}
export function download(id) {
  return request({
    url: `/download-document/${id}`,
    method: "get",
    responseType: "blob",
  });
}
